<script>
import DatePicker from "vue2-datepicker";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import WidgetCard from "@/views/pages/userActivity/gameActivity";
//import RevenueAnalytics from "@/views/pages/dashboard/revenue";
//import SalesAnalytics from "@/views/pages/dashboard/sales-analytics";
//import EarningReport from "@/views/pages/dashboard/earning";
import PlayerGame from "@/views/pages/userActivity/player-pergame";
import GamesPlayed from "@/views/pages/userActivity/gamesplayed-pergame";
import FavoriteGame from "@/views/pages/userActivity/mostPlayed";
import PortalActivity from "@/views/pages/userActivity/portalActivity";
import pageView from "@/views/pages/userActivity/pageView";
import dailyActiveUser from "@/views/pages/userActivity/dailyActiveUser";
import monthlyActiveUser from "@/views/pages/userActivity/monthlyActiveUser";
import pageChartView from "@/views/pages/userActivity/pageChartView";
//import Chat from "./chat";
//import Transaction from './transaction';
//import GameCategory from '@/views/pages/dashboard/game-category';

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    WidgetCard,
    PlayerGame,
    GamesPlayed,
    FavoriteGame,
    PortalActivity,
    pageView,
    dailyActiveUser,
    monthlyActiveUser,
    DatePicker,
    pageChartView
  },
  data() {
    return {
      page: {
    title: "User Activity",
    meta: [{ name: "description", content: appConfig.description }]
  },
      title: "User Activity",
      items: [
        {
          text: "Coregames"
        },
        {
          text: "User Activity",
          active: true
        }
      ],
      roleId: JSON.parse(localStorage.getItem('user')).data.menu_role_id || 0,
      setMonth: '',
      setYear:'',
      daterange:'',
      startDate:"",
      endDate:"",
      

    };
  },
  methods:{
    setDate(){
      var date = new Date();
      // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      // var startDate = date.setDate(firstDay.getDate());
      this.startDate = date.getFullYear()+'-'+(date.getMonth()+1)+'-01';
      this.endDate = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();

      console.log(this.endDate);
    },
    setStartDate(e){
        console.log(e);
        this.startDate = e;
    },
    setEndDate(e){
        console.log(e);
        this.endDate = e;
    },
    filterDate(){
      if(this.roleId == 2) {
        this.$refs.gameActivity.getVisitor();
      }else{
        this.$refs.gameActivity.activeSubs();
      }
    }
  },
  mounted(){
    this.setDate();
    //console.log(this.setMonth, 'dashboard');
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-xl-12">
          <b-card>
          <h5 slot="header" class="mb-0">Game Activity</h5>
          <div class="row">
      <div class="col-xl-2">
      <div class="form-group mb-3">
                <label>Start Date</label>
                <br />
                <date-picker v-model="startDate" format="Y-M-DD" value-type="format" :first-day-of-week="1" lang="en" placeholder="Select Date" @change="setStartDate"></date-picker>
              </div>
      </div>
      <div class="col-xl-3">
      <div class="form-group mb-3">
                <label>End Date</label>
                <br />
                <date-picker v-model="endDate" format="Y-M-DD" value-type="format" :first-day-of-week="1" lang="en" placeholder="Select Date" @change="setEndDate"></date-picker>
              </div>
      </div>
      <div class="col-xl-2">
        <button type="button" class="btn btn-primary btn-sm" style="margin-top :2rem" @click.prevent="filterDate">Filter</button>
      </div>       
    </div>
    <div class="row">
      <div class="col-xl-12">
        <WidgetCard ref="gameActivity" v-if="roleId===2 || roleId===1" :startDate="startDate" :endDate="endDate"/>
        
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <PlayerGame />
      </div>
      <div class="col-lg-4">
        <GamesPlayed />
      </div>
      <div class="col-lg-4">
        <FavoriteGame />
      </div>
    </div>
        </b-card>

      </div>
    </div>
        
    <div class="row">
      <div class="col-xl-12">
          <b-card>
          <h5 slot="header" class="mb-0">Portal Activity</h5>
    <div class="row">
    </div>
    <div class="row">
      <div class="col-xl-12">
      <PortalActivity ref="portalActivity" v-if="roleId===2 || roleId===1" :startDate="startDate" :endDate="endDate"/>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <pageView />
      </div>
      <div class="col-lg-4">
        <dailyActiveUser />
      </div>
      <div class="col-lg-4">
        <monthlyActiveUser />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <pageChartView />
      </div>
    </div>
        </b-card>

      </div>
    </div>
  </Layout>
</template>