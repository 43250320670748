<script>
/**
 * Widget Component
 */
export default {
  data() {
    return {
      statData: [
        {
          title: "Unique Players",
          icon: "ri-stack-line",
          value: "20",
          subvalue: " 2.4% ",
          color_card: "bg-soft-danger"
        },
        {
          title: "Most Played Game",
          icon: "ri-gamepad-line",
          value: "Tembak!",
          subvalue: " 2.4% ",
          color_card: "bg-success"

        },
        {
          title: "Most Active Player",
          icon: "ri-game-line",
          value: "25",
          subvalue: " 2.4% ",
          color_card: 'bg-warning'
        }
      ]
    };
  }
};
</script>

<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div :class="`card-body ${data.color_card}`">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{data.title}}</p>
              <h4 class="mb-0">{{data.value}}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>